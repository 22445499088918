
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType } from "prometheus-synced-ui";
import { LITE_FEATURE, PenetrantDetails, PenetrantItem } from "@/domain";

import { IPenetrantService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class PenetrantsTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get penetrantService() {
    return this.container.resolve<IPenetrantService>(S.PENETRANT_SERVICE);
  }

  get existingSerialNumbers() {
    return _.map(this.penetrants, (p) => p.serialNumber);
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/PenetrantCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/PenetrantUpdate.vue");
  }

  get headers() {
    return [
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Type",
        align: "center",
        sortable: true,
        value: "equipmentType",
      },
      {
        text: "Marque",
        align: "center",
        sortable: true,
        value: "equipmentBrand.name",
      },
      {
        text: "Numéro de PV",
        align: "center",
        sortable: true,
        value: "pvNumber",
      },
      {
        text: "Fluo / Non Fluo",
        align: "center",
        sortable: true,
        value: "isFluorescent",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  penetrants: PenetrantItem[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.penetrants = await this.penetrantService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
        includeFluorescent: true,
        includeColored: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(penetrant: PenetrantDetails) {
    this.penetrants.push(PenetrantItem.fromDetails(penetrant));
  }

  update(penetrant: PenetrantDetails) {
    const index = _.findIndex(this.penetrants, (p) => p.id == penetrant.id);
    if (index != -1) {
      this.penetrants.splice(index, 1, PenetrantItem.fromDetails(penetrant));
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const penetrant = _.find(this.penetrants, (p) => p.id == id)!;
      const result = await this.penetrantService.update(id, {
        serialNumber: penetrant.serialNumber,
        equipmentType: penetrant.equipmentType,
        equipmentBrandId: penetrant.equipmentBrand.id,
        pvNumber: penetrant.pvNumber,
        isFluorescent: penetrant.isFluorescent,
        disabled: !penetrant.disabled,
      });
      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
